import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const icons = {
  mail: () => (
    <svg
      version="1.1"
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <path
        className="st0"
        d="M0,5V19c0,1.1,0.9,2,2,2H22c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2H2C0.9,3,0,3.9,0,5z M21.5,5l-8.3,6.7c-0.7,0.6-1.7,0.6-2.4,0L2.5,5L21.5,5z M2,19V7.2l8.2,6.7c1,0.8,2.5,0.8,3.6,0L22,7.2V19H2z"
      />
    </svg>
  ),
  twitter: () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        className="st0"
        d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-3.2,0-5.5,3-4.8,6C7.7,8.1,4.1,6.1,1.7,3.1C0.4,5.4,1,8.3,3.2,9.7C2.4,9.7,1.6,9.5,1,9.1c-0.1,2.3,1.6,4.4,3.9,4.9 c-0.7,0.2-1.5,0.2-2.2,0.1c0.6,2,2.4,3.4,4.6,3.4c-2.1,1.6-4.7,2.3-7.3,2c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14.3-7.7,14-14.6 C22.5,6.4,23.3,5.5,24,4.6z"
      />
    </svg>
  ),
  instagram: () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
      <path
        className="st0"
        d="M12,2.2c3.2,0,3.6,0,4.9,0.1c3.3,0.1,4.8,1.7,4.9,4.9c0.1,1.3,0.1,1.6,0.1,4.8c0,3.2,0,3.6-0.1,4.8 c-0.1,3.2-1.7,4.8-4.9,4.9c-1.3,0.1-1.6,0.1-4.9,0.1c-3.2,0-3.6,0-4.8-0.1c-3.3-0.1-4.8-1.7-4.9-4.9c-0.1-1.3-0.1-1.6-0.1-4.8 c0-3.2,0-3.6,0.1-4.8c0.1-3.2,1.7-4.8,4.9-4.9C8.4,2.2,8.8,2.2,12,2.2z M12,0C8.7,0,8.3,0,7.1,0.1c-4.4,0.2-6.8,2.6-7,7 C0,8.3,0,8.7,0,12s0,3.7,0.1,4.9c0.2,4.4,2.6,6.8,7,7C8.3,24,8.7,24,12,24s3.7,0,4.9-0.1c4.4-0.2,6.8-2.6,7-7C24,15.7,24,15.3,24,12 s0-3.7-0.1-4.9c-0.2-4.4-2.6-6.8-7-7C15.7,0,15.3,0,12,0z M12,5.8c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2 C18.2,8.6,15.4,5.8,12,5.8z M12,16c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C16,14.2,14.2,16,12,16z M18.4,4.2 c-0.8,0-1.4,0.6-1.4,1.4S17.6,7,18.4,7c0.8,0,1.4-0.6,1.4-1.4S19.2,4.2,18.4,4.2z"
      />
    </svg>
  ),
  artstation: () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105.8 93.1">
      <g>
        <path
          className="st0"
          d="M0,71.8l8.9,15.4l0,0c1.8,3.5,5.4,5.9,9.5,5.9l0,0l0,0h59.3L65.4,71.8L0,71.8L0,71.8z"
        />
        <path
          className="st0"
          d="M105.8,71.9c0-2.1-0.6-4.1-1.7-5.8L69.3,5.7C67.5,2.3,64,0,59.9,0H41.5l53.7,93l8.5-14.7 C105.3,75.5,105.8,74.3,105.8,71.9z"
        />
        <polygon className="st0" points="56.7,56.6 32.8,15.1 8.8,56.6 	" />
      </g>
    </svg>
  ),
  twitch: () => (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <path
        className="st0"
        d="M2.1,0L0.5,4.1V21h5.7v3h3.2l3-3h4.7l6.3-6.3V0C23.5,0,2.1,0,2.1,0z M21.3,13.6l-3.6,3.6H12l-3,3v-3H4.1v-15 h17.2V13.6z M17.7,6.3v6.3h-2.1V6.3C15.6,6.3,17.7,6.3,17.7,6.3z M12,6.3v6.3H9.9V6.3C9.9,6.3,12,6.3,12,6.3z"
      />
    </svg>
  ),
  linkedin: () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
      <path
        className="st0"
        d="M19,0H5C2.2,0,0,2.2,0,5v14c0,2.8,2.2,5,5,5h14c2.8,0,5-2.2,5-5V5C24,2.2,21.8,0,19,0z M8,19H5V8h3V19z M6.5,6.7c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8S8.3,4,8.3,5S7.5,6.7,6.5,6.7z M20,19h-3v-5.6c0-3.4-4-3.1-4,0V19h-3V8h3v1.8 c1.4-2.6,7-2.8,7,2.5V19z"
      />
    </svg>
  ),
  youtube: () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
      <path
        className="st0"
        d="M19.6,3.2C16,2.9,8,2.9,4.4,3.2C0.5,3.5,0,5.8,0,12c0,6.2,0.5,8.5,4.4,8.8c3.6,0.2,11.6,0.2,15.2,0 c3.9-0.3,4.4-2.6,4.4-8.8C24,5.8,23.5,3.5,19.6,3.2z M9,16V8l8,4L9,16z"
      />
    </svg>
  ),
};

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <div className="container ai-gap--lg">
        <div className="row">
          <div className="col-xs-12 ai-gap--md">
            <ul className="ai-about-icons ai-about-icons--white">
              <li className="ai-about-icons__element ai-about-icons__element--no-margin">
                <a href="mailto:alexillustrationcontact@gmail.com">
                  <icons.mail />
                </a>
              </li>
              <li className="ai-about-icons__element ai-about-icons__element--no-margin">
                <a href="https://www.linkedin.com/in/alejandro-aliseda-87680a172/">
                  <icons.linkedin />
                </a>
              </li>
            </ul>
            <div className="ai-separator" />
            <div className="ai-align-middle">
              <a href="mailto:alexillustrationcontact@gmail.com">
                alexillustrationcontact@gmail.com
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 ai-gap--md">
            <div className="ai-align-middle">Follow me on:</div>
            <ul className="ai-about-icons ai-about-icons--white">
              <li className="ai-about-icons__element ai-about-icons__element--no-margin">
                <a href="https://twitter.com/alex_illustrat">
                  <icons.twitter />
                </a>
              </li>
              <li className="ai-about-icons__element ai-about-icons__element--no-margin">
                <a href="https://www.instagram.com/alex.illustration">
                  <icons.instagram />
                </a>
              </li>
              <li className="ai-about-icons__element ai-about-icons__element--no-margin">
                <a href="https://www.artstation.com/alexillustration">
                  <icons.artstation />
                </a>
              </li>
              <li className="ai-about-icons__element ai-about-icons__element--no-margin">
                <a href="https://www.youtube.com/channel/UC8Zape3qnnHCqZzlVKerOZQ/">
                  <icons.youtube />
                </a>
              </li>
              <li className="ai-about-icons__element ai-about-icons__element--no-margin">
                <a href="https://www.twitch.tv/alex_illustration">
                  <icons.twitch />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
